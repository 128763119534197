<template>
  <section class="notSupported">
    <div class="notSupported_inner">
      <h1>Not Supported</h1>
      <router-link to="/">Back to home</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotSupported",
};
</script>

<style lang="scss" scoped>
@import "./../styles/styles.scss";

.notSupported {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &_inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}
</style>
